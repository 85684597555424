import BaseDiagnosticsAdapter from './base_diagnostics_adapter'
// LocalVideoStream is to allow using ACS code to "test it" like viso will do when using real call feature
import { LocalVideoStream, VideoStreamRenderer, Features } from "@azure/communication-calling"
import { initializeAcsComponents } from '../../services/azure_communication_service'


class AcsDiagnosticsAdapter extends BaseDiagnosticsAdapter {
    constructor(acsAccessToken) {
        super()
        this.acsAccessToken = acsAccessToken
    }

    async startBandwidthTest() {
        await this.setupVisioProvider()

        try {
          const preCallDiagnosticsResult = await this.callClient.feature(Features.PreCallDiagnostics).startTest(this.tokenCredential)
          const inCallDiagnostics = await preCallDiagnosticsResult?.inCallDiagnostics

          switch(inCallDiagnostics?.bandWidth) {
            case 'Good' :
              return 'excellent'
            case  'Average' :
              return 'good'
            case null :
              return 'unknow'
            default :
                return 'poor'
          }
        } catch (error) {
          return 'error'
        }
    }

    async setupVisioProvider() {
      if (!this.callClient && !this.callAgent) {
        const { callClient, callAgent, tokenCredential } = await initializeAcsComponents(this.acsAccessToken)

        this.callAgent = callAgent
        this.callClient = callClient
        this.tokenCredential = tokenCredential
      }
    }

    async deviceManager() {
      if (!this._deviceManager) {
        await this.setupVisioProvider()
        this._deviceManager = await this.callClient.getDeviceManager()
        await this._deviceManager.askDevicePermission({ audio: true, video: true })
        this._deviceManager.on('audioDevicesUpdated', (e) => {
          const event = new CustomEvent("visioCall:audioDevicesChange", { detail: e})

          window.dispatchEvent(event)
        } )
        this._deviceManager.on('videoDevicesUpdated', (e) => {
          const event = new CustomEvent("visioCall:videoDevicesChange", { detail: e})
          window.dispatchEvent(event)
        } )
        //trigger a manual event to refresh devices if permission are already granted
        const event = new CustomEvent("visioCall:audioDevicesChange")
        window.dispatchEvent(event)
      }
      return this._deviceManager
    }

    async startVideoTest(videoElement) {
      const deviceManager = await this.deviceManager()

      const lastUsedVideoDeviceId = localStorage.getItem('acs:lastUsedVideoDeviceId')
      this.videoDevice = (await deviceManager.getCameras()).find(
        (videoDevice) => videoDevice.id === lastUsedVideoDeviceId
      ) || (await deviceManager.getCameras())[0];

      const localVideoContainer = videoElement.parentElement
      if (this.videoDevice) {
          this.localVideoStream = new LocalVideoStream(this.videoDevice)
          const videoStreamRenderer = new VideoStreamRenderer(this.localVideoStream)
          const view = await videoStreamRenderer.createView()
          view.target.getElementsByTagName('video')[0].setAttribute('playsInline', 'true')
          view.target.getElementsByTagName('video')[0].setAttribute('style', 'width: 100%; height: 100%; object-fit: fill;')

          videoElement.classList.add("d-none")
          localVideoContainer.appendChild(view.target.getElementsByTagName('video')[0]);
      } else {
          //dev note should raise to allow interception at the controller level
          console.error(`No camera device found on the system`);
      }
    }

    async getAvailableOutputAudioDevices() {
      const deviceManager = await this.deviceManager()

      if (deviceManager.isSpeakerSelectionAvailable) {
        return deviceManager.getSpeakers()
      } else {
        return Promise.resolve([])
      }
    }

    async getAvailableInputAudioDevices() {
      const deviceManager = await this.deviceManager()

      try {
        return deviceManager.getMicrophones()
      } catch(_) {
        return Promise.resolve([])
      }
    }


    async getAvailableVideoDevices() {
      const deviceManager = await this.deviceManager()

      try {
        return deviceManager.getCameras()
      } catch(_) {
        return Promise.resolve([])
      }
    }

    async setOutputAudioDevice(audioDeviceId) {
      this.outputAudioDeviceId = audioDeviceId?.replace(/^.*:/, "")
      // use given audioDeviceId it come from acs DeviceManager
      // the gsubed on is for regular audio test code not managed by azure libs
      try {
        await this.audioElement?.setSinkId(this.outputAudioDeviceId)
      } catch(e) {
        // no device found. Mean last used device is not present so setting to null
        // will fallback to defaut audio device
      }
      localStorage.setItem('acs:lastUsedOutputAudioDeviceId', audioDeviceId)

    }

    setInputAudioDevice(audioDeviceId) {
      this.inputAudioDeviceId = audioDeviceId?.replace(/^.*:/, "")
      localStorage.setItem('acs:lastUsedInputAudioDeviceId', audioDeviceId)
    }

    async setVideoDevice(videoDeviceId) {
      const deviceManager = await this.deviceManager()

      this.videoDevice = (await deviceManager.getCameras()).find(
        (videoDevice) => videoDevice.id === videoDeviceId
      )

      localStorage.setItem('acs:lastUsedVideoDeviceId', this.videoDevice.id)
      await this.localVideoStream.switchSource(this.videoDevice);
    }

    stopVideoStream(_videoElement) {
    }

    async cleanOnDisconnect() {
        this.callAgent?.dispose()
    }
}

export default AcsDiagnosticsAdapter
