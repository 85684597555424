import { CallClient } from "@azure/communication-calling"
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { setLogLevel, createClientLogger, AzureLogger } from '@azure/logger';

export async function initializeAcsComponents(acsAccessToken) {
    setLogLevel('error');
    let logger = createClientLogger('ACS');
    AzureLogger.log = (...args) => {
        if (document?.body?.dataset?.environment === 'development') {
            console.error(...args)
        } else {
            newrelic.noticeError(...args,
              { scopeName: window.scopeName,
                scopeInCustomVisio: window.scopeInCustomVisio,
                userUrlToken: window.userUrlToken});
        }
    };
    const callClient = new CallClient({ logger });
    const tokenCredential = new AzureCommunicationTokenCredential(acsAccessToken)
    const callAgent = await callClient.createCallAgent(tokenCredential)

    return { callClient, callAgent, tokenCredential }
}

